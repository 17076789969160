import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/callToAction"
import Header from "../components/header"
import SolutionsList from "../components/solutionsList"
import Footer from "../components/footer"
import Intro from "../components/intro"
import TextWithImage from "../components/textWithImage"

const SolutionsPage = ({ data: { page } }) => {
  return (
    <Layout>
      <SEO title={page.title} />
      <Header
        title={page.title}
        image={page.heroImage ? page.heroImage.url : null}
      />
      {page.content.map(section => (
        <section key={section.id}>
          {section.model.apiKey === "text_with_image" && (
            <TextWithImage section={section} headingDowncase />
          )}
          {section.model.apiKey === "solutions_list" && (
            <SolutionsList section={section} />
          )}
          {section.model.apiKey === "intro" && (
            <Intro title={section.title} text={section.text} heading="h2" />
          )}
        </section>
      ))}
      <CallToAction cta={page.callToAction} />
      <Footer />
    </Layout>
  )
}

export default SolutionsPage

export const query = graphql`
  query SolutionsPageQuery {
    page: datoCmsSolutionsPage {
      title
      subtitle
      heroImage {
        url
      }
      content {
        ... on DatoCmsSolutionsList {
          id
          model {
            apiKey
          }
          solution {
            id
            title
            image {
              alt
              url
            }
          }
        }
        ... on DatoCmsIntro {
          id
          model {
            apiKey
          }
          title
          text
        }
        ... on DatoCmsTextWithImage {
          id
          title
          text
          model {
            apiKey
          }
          image {
            url
          }
        }
      }
      callToAction {
        image {
          url
        }
        text
        url
        callToAction
      }
    }
  }
`
