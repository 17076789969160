import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Container from "./container"
import { ItemsList } from "./utils"

const solutionsList = ({ section }) => (
  <Wrapper>
    <Container>
      <CustomList>
        {section.solution.map(solution => (
          <Item key={solution.id}>
            <img src={solution.image.url} alt={solution.image.alt} />
            <p>{solution.title}</p>
          </Item>
        ))}
      </CustomList>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  overflow: hidden;
  h2 {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
`

const CustomList = styled(ItemsList)`
  @media screen and (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

const Item = styled.li`
  border: 1px solid ${DesignTokens.colors.black};
  border-bottom: 6px solid ${DesignTokens.colors.primary[500]};
  margin-bottom: 4%;
  img {
    width: 100%;
  }
  p {
    padding: ${DesignTokens.spacing[3]};
    color: ${DesignTokens.colors.primary[500]};
    font-weight: 900;
  }
  @media screen and (min-width: 769px) {
    width: calc(96% / 2);
    margin-right: 4%;
    &:nth-child(even) {
      margin-right: 0;
    }
  }
`

export default solutionsList
